import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const unreadMessageSlice = createSlice({
  name : "unreadMessage",
  initialState : {
    value: false,
    numbers : [] as string[]
  },
  reducers:{
    setUnread : (state, action: PayloadAction<string>) => {
      state.numbers.push(action.payload)
      state.value = true
    },
    resetUnread : (state, action: PayloadAction<string>) =>{
      state.value = false
      state.numbers = state.numbers.filter((n) => n !== action.payload);
    }
  }
})


export const {
  setUnread,
  resetUnread,
} = unreadMessageSlice.actions;

export default unreadMessageSlice.reducer;
