import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const hotnessBySlice = createSlice({
  name : "hotnessBy",
  initialState : {
    value: "all"
  },
  reducers:{
    selectHotnessBy: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
    resetHotnessBy : (state) =>{
      state.value = ""
    }
  }
})


export const {
  selectHotnessBy,
  resetHotnessBy,
} = hotnessBySlice.actions;

export default hotnessBySlice.reducer;
