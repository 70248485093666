import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const newMessageSlice = createSlice({
    name : "newMessages",
    initialState : {
        value: ""
    },
    reducers:{
        set : (state, action: PayloadAction<string>) => {
              state.value = action.payload
        },
        reset : (state) =>{
            state.value = ""
        }
    }
})


export const {
    set,
    reset,
  } = newMessageSlice.actions;

  export default newMessageSlice.reducer;