import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const phoneContactSlice = createSlice({
  name : "phoneContact",
  initialState : {
    value: {}
  },
  reducers:{
    fillPhoneContact: (state, action: PayloadAction<object>) => {
      state.value = action.payload
    },
    resetPhoneContact : (state) =>{
      state.value = {}
    }
  }
})


export const {
  fillPhoneContact,
  resetPhoneContact
} = phoneContactSlice.actions;

export default phoneContactSlice.reducer;
