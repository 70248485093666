import React from 'react';
import { Row, Col, Card, Form, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';


export default function SignUp() {

  return (
    <div className="w-full flex flex-col items-center justify-center min-h-screen py-2">
      <div className="flex flex-col items-center mt-10 p-10 shadow-md">
        <Row className="align-items-center justify-content-center g-0 min-vh-100">
          <Col xxl={4} lg={6} md={8} xs={12} className="py-8 py-xl-0">
            {/* Card */}
            <Card className="smooth-shadow-md">
              {/* Card body */}
              <Card.Body className="p-6">
                <div className="mb-4">
                  <Link to="/"><Image src="/images/brand/logo/logo-primary.svg" className="mb-2" alt="" /></Link>
                  <p className="mb-6">Please enter your user information.</p>
                </div>
                {/* Form */}
                {/* {hasMounted &&  */}
                <Form>
                  <Form.Group className="mb-3" controlId="username">
                    <Form.Label>Username or email</Form.Label>
                    <Form.Control type="text" name="username" placeholder="User Name" required={true} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" placeholder="Enter address here" required={true} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name="password" placeholder="**************" required={true} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="confirm-password">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" name="confirm-password" placeholder="**************" required={true} />
                  </Form.Group>

                  <div className="mb-3">
                    <Form.Check type="checkbox" id="check-api-checkbox">
                      <Form.Check.Input type="checkbox" />
                      <Form.Check.Label>
                        I agree to the <Link to="#"> Terms of Service </Link> and <Link to="#"> Privacy Policy.</Link>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>

                  <div>
                    <div className="d-grid">
                      <Button variant="primary" type="submit">Create Free Account</Button>
                    </div>
                    <div className="d-md-flex justify-content-between mt-4">
                      <div className="mb-2 mb-md-0">
                        <Link to="/auth/sign-in" className="fs-5">Already member? Login </Link>
                      </div>
                      <div>
                        <Link to="/auth/forget-password" className="text-inherit fs-5">Forgot your password?</Link>
                      </div>
                    </div>
                  </div>
                </Form>

              </Card.Body>
            </Card>

          </Col>
        </Row>
      </div>
    </div>
  );

}
