const historicalService = {
    useNameToColor(name: string) {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }


        let color = '#';
        for (let i = 0; i < 3; i++) {
          let value = (hash >> (i * 8)) & 0xFF;
          // Ajustar el valor para que sea más alto (más brillante)
          value = Math.floor((value + 255) / 2);
          color += ('00' + value.toString(16)).substr(-2);
        }

        return color;
    },

    useInitials(input: string) {
        const words = input.split(' ');
        let newInitials = '';
        if (words.length > 1) {
            newInitials = words[0][0] + words[1][0];
        } else if (words.length === 1) {
            newInitials = words[0][0];
        } else {
            newInitials = input[0];
        }

        const regex = /[^a-zA-Z0-9]/g;
        newInitials = newInitials.replace(regex, '');
        if (newInitials === '') {
            newInitials = 'U';
        }

        return newInitials;
    }
}

export default historicalService;
