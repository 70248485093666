import { Cross } from 'akar-icons';
import React, { useState, ReactNode, useRef, useEffect } from 'react';

interface CustomDropdownProps {
  label: string;
  model: string;
  options: Array<{ label: string; value: string }>;
  onChange: (value: string) => void;
  icon?: ReactNode;
  isSmall?: boolean | undefined;
  disabled?: boolean | undefined;
}

const CustomDropdown = ({ label, model, options, isSmall, onChange, icon, disabled }: CustomDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && (dropdownRef.current as HTMLElement).contains(event.target)) {
        return;
      } else {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={dropdownRef} className="relative cursor-pointer">
      <div
        onClick={() => { (!disabled) ? toggleDropdown() : console.log("disabled") }}
        className={(label === "Mostrar")? "phone-removed flex items-center text-xs xl:text-sm bg-white text-gray-700 font-medium py-2 rounded" : "flex items-center text-xs xl:text-sm bg-white text-gray-700 font-medium py-2 rounded"}
      >
        {icon && <span className={(isSmall) ? "mr-1" : "w-3 mr-1"}>{icon}</span>}
        <span className={(isSmall) ? "small-text" : ""}>{label}</span>
      </div>
      {isOpen && (
        <div className="phone-dropdown origin-top-right absolute z-50 right-0 mt-0 text-sm w-[160px] rounded-md bg-white shadow-lg">
          <label className="flex items-center space-x-2 ml-auto">
            <div
              onClick={toggleDropdown}
              className="btn-close flex items-center text-sm transparent text-gray-700 font-medium p-1 rounded-md"
            >
              <Cross size={16} />
            </div>
          </label>
          <ul className="py-2 text-gray-700">
            {options.map((option: any) => (
              <li
                key={option.value}
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}
                className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${model === option.value ? 'text-blue-500' : ''}`}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
