import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import Logo2 from "../../../assets/img/Logoblanco.png"
import useMobileDetect from "../../../services/movil/useMobileDetect";
import config from '../../../config/config'
import { useAuth } from "../../../context/auth/AuthContext";
import { state } from '../../../redux/reducers/changeMenu';
import { useSelector, useDispatch } from 'react-redux';
import { Cross } from "akar-icons";

export default function LeftNavbar() {
  const { session, logout } = useAuth();
  const dispatch = useDispatch();
  const menu = useSelector((state:any) => state.Menu.value);
  const location = useLocation();
  const [menus, setMenu] = useState(false);
  let hasPedidos = (config.ACCESS_NAVBAR ?? '').split(',').filter((value) => (value === 'pedidos')).length > 0
  const { basePath, isMobile } = useMobileDetect();
  const navbarRef = useRef(null);
  const changeMenu = () => {
    dispatch(state(true));
  };

  const [isClickDisabled, setIsClickDisabled] = useState(false);

  const handleClickOutsideNavbar = (event:any) => {
    event.stopPropagation();
  
    if (isClickDisabled) {
      return;
    }
  
    setIsClickDisabled(true);
    setTimeout(() => setIsClickDisabled(false), 300); // Disable click for 300ms
  
    if (navbarRef.current && (navbarRef.current as HTMLElement).contains(event.target)) {
      return;
    } else {
      if(isMobile){
        changeMenu()
      }
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideNavbar);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideNavbar);
    };
  }, []);

  return (
    session ? (
      <>
        <nav ref={navbarRef} className={(menu && isMobile) ? "bg-slate-900 row-menu items-center text-white flex-space movil" : "bg-slate-900 row-menu items-center text-white flex-space movil-desplegate"}>
          <div className="menu-all">
            {/*<div onClick={() => setMenu(!menus)}  className={(!menus)?"btn-menu" : "btn-menu btn-menu-desactivado"}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.25 14.94L6.36 10.05C5.7825 9.4725 5.7825 8.5275 6.36 7.95L11.25 3.06" stroke="#167AF0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>*/}
            <div onClick={() => {(isMobile) && changeMenu() }}  className="btn-close btn-menu-x">
              <Cross size={16}></Cross>
            </div>
            <Link className="flex-btn" onClick={() => {(isMobile) && changeMenu() }} to={`${basePath}/historial`}>
              <img src={Logo2} alt="" />
            </Link>
            <div className={(menus) ? "body-menu-yavendio" : "body-menu-yavendio"}>
              <div className="flex menu-column items-center">
                <div className={(menus) ? "center-menu" : "center-menu"}>
                  <Link onClick={() => {(isMobile) && changeMenu() }} className={(location.pathname == `${basePath}/`) ? "btn_dashboard btn-menu-normal-notClickable" : "btn_dashboard btn-menu-normal-notClickable"} to={`${basePath}/`}>
                    Dashboard
                  </Link>
                  <Link onClick={() => {(isMobile) && changeMenu() }} className={(location.pathname == `${basePath}/historial` || location.pathname == `${basePath}/`) ? "btn-menu-normal btn-menu-normal-activado" : "btn-menu-normal"} to={`${basePath}/historial`}>
                    Conversaciones
                  </Link>
                  {/*<Link className={(location.pathname == `${basePath}/pedidos`) ? "btn-menu-normal btn-menu-normal-activado" : "btn-menu-normal"} to={`${basePath}/pedidos`}>
                    Pedidos
                  </Link>*/}
                </div>
              </div>
              <div className="bottom-link">
                <div className="flex-column w-100 items-center">
                  <a className="btn-menu-close" onClick={logout}>
                    Cerrar Sesión
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    ) : (
      <></>
    )
  );

};
