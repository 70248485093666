import { verifyTokenExpiration } from "../../helper/helpers";
import loginService from "../../services/login/loginService";

class SessionManager {
  static saveSession(session: any) {
    localStorage.setItem('session', JSON.stringify(session));
  }

  static getSession() {
    const session = localStorage.getItem('session');
    return session ? JSON.parse(session) : null;
  }

  static async getBearerToken() {
    let data = localStorage.getItem('session');

    if (data) {
      let session = JSON.parse(data);
      let isExpiry = await verifyTokenExpiration(session.access_token);

      if (isExpiry) {
        let newToken = await loginService.refreshToken(session.refresh_token);
        let { access_token, ...data } = session

        this.saveSession({
          access_token: newToken,
          ...data
        });

        return `Bearer ${newToken}`;
      } else {
        return `Bearer ${session.access_token}`;
      }
    }

    return 'Bearer ';
  }

  static removeSession() {
    localStorage.removeItem('session');
  }
}

export default SessionManager;
