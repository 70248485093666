import axios from 'axios'
import config from '../../config/config'

import SessionManager from '../../context/session/SessionManager';

const historicalService = {

  async getConversations(currentPage: Number, perPage: Number, cTemperature: string, sortBy: string, filterBy: string, dateFilterBy: string, qSearch: string): Promise<any> {
    try {
      let url: string[] = [
        config.BACKEND_API_URL ?? '',
        '/Conversaciones',
        `?current_page=${currentPage}`,
        `&per_page=${perPage}`,
      ];

      if (cTemperature.length > 0) {
        url.push(`&conv_temperature=${cTemperature}`);
      }
      if (sortBy.length > 0) {
        url.push('&sort_by=' + sortBy);
      }
      if (filterBy.length > 0) {
        url.push('&filter_by=' + filterBy);
      }
      if (dateFilterBy.length > 0) {
        url.push('&date_filter_by=' + dateFilterBy);
      }
      if (qSearch.length > 0) {
        url.push('&q_search=' + qSearch);
      }

      return await axios.get(url.join(''), {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'application/json',
          Authorization: await SessionManager.getBearerToken(),
        },
        withCredentials: true,
      });
    } catch (error) {
      console.error("ERROR =", error);
      throw error
    }
  },
  async getMessageStoppedAll(): Promise<any> {
    try {
      let url = [
        config.BACKEND_API_URL,
        '/message-stopped/all'
      ];
      return await axios.get(url.join(''), {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'application/json',
          Authorization: await SessionManager.getBearerToken(),
        },
        withCredentials: true,
      })
    } catch (error) {
      console.error("ERROR =", error);
    }
  },
  async postTurnManualMode(manualModeOn: any): Promise<any> {
    try {
      return await axios.post(`${config.BACKEND_API_URL}/Manual_mode`, manualModeOn, {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'application/json',
          Authorization: await SessionManager.getBearerToken(),
        },
        withCredentials: true,
      });
    } catch (error) {
      console.error("ERROR =", error);
    }
  },
  async postTurnAutomaticMode(manualModeOff: any): Promise<any> {
    try {
      const response = await axios.post(`${config.BACKEND_API_URL}/Resume_auto`, manualModeOff , {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'application/json',
          Authorization: await SessionManager.getBearerToken(),
        },
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error("ERROR =", error);
    }
  },
  async postMessageNotReaded(unreadMsg: any): Promise<any> {
    try {

      const response = await axios.post(`${config.BACKEND_API_URL}/mensaje_no_leido`, unreadMsg , {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'application/json',
          Authorization: await SessionManager.getBearerToken(),
        },
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      console.error("ERROR =", error);
    }
  },
  async postSentMessageManual(messageAdmin: any): Promise<any> {
    try {
      return await axios.post(`${config.BACKEND_API_URL}/Re_manual`, messageAdmin , {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'application/json',
          Authorization: await SessionManager.getBearerToken(),
        },
        withCredentials: true,
      });
    } catch (error) {
      console.error("ERROR =", error);
    }
  },
  async postUpdateQuantity(messageAdmin: any): Promise<any> {
    try {
      const response = await axios.post(`${config.BACKEND_API_URL}/actualizar_cantidad`, messageAdmin , {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'application/json',
          Authorization: await SessionManager.getBearerToken(),
        },
        withCredentials: true,
      });
      return response.data
    } catch (error) {
      console.error("ERROR =", error);
    }
  },
  async postSentMessageManualFile(phoneCustomer: any, file: any): Promise<any> {
    try {
      let formData = new FormData();
      formData.append("phone_client", phoneCustomer);
      formData.append("file", file);

      const response = await axios.post(`${config.BACKEND_API_URL}/upload`, formData , {
        headers: {
          'Access-Control-Allow-Origin': config.BACKEND_API_URL,
          'Content-Type': 'multipart/form-data',
          Authorization: await SessionManager.getBearerToken(),
        },
        withCredentials: true,
      });
      return response.data
    } catch (error) {
      console.error("ERROR =", error);
    }
  }
}

export default historicalService
