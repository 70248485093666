import {formatDatetimeFriendly, truncateString, parseTimestamp} from "../../../helper/helpers";
import React, {useEffect, useState} from "react";

import {select} from "../../../redux/features/selectorSlice";
import {resetUnread} from "../../../redux/features/unreadMessageSlice";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import useProfile from '../../../services/profile/useGenerateProfile';

export default function ChatListItem({ items }: any) {
  const [clicked, setClicked] = useState("");
  const [isScrolling, setIsScrolling] = useState(false);

  
  const dispatch = useAppDispatch();

  const newMsgNotif = useAppSelector(state => state.newMessageReducer.value);

  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const value = e.currentTarget.getAttribute('value');
    dispatch(select(value as any));
    dispatch(resetUnread(value as any));
    
    setClicked(value as string);
    console.log(newMsgNotif)
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
      setTimeout(() => {
        setIsScrolling(false);
      }, 2000);
    };

    const list = document.querySelector('.chat_list_items');
    list?.addEventListener('scroll', handleScroll);

    return () => list?.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ol className={`chat_list_items h-full mb-3 ${isScrolling ? '' : 'hide-scrollbar'}`}>
      { items.map(({ number, timestamp, nameClient, chat, profile }: any) => clicked === number
        ?
          <li
            key={number}
            className="client_list_elem_clicked px-2.5 py-3.5 gap-3 flex flex-row"
            onClick={handleClick}
            value={number}
          >
            <div className="profile-container">
             <div style={{background: nameClient ? useProfile.useNameToColor(nameClient) : useProfile.useNameToColor(number)}} className="profile_img">{nameClient ? useProfile.useInitials(nameClient) : useProfile.useInitials(number)}</div>
            </div>
            <div className="chat-container">
              <div className="name_notif">
                {nameClient === "" && <h5 className="client_name_tag text-sm xl:text-[16px]">{nameClient ? "" : number}</h5>}
                {
                  nameClient && <h5 className="client_name_tag text-sm xl:text-[16px]">{nameClient}</h5>
                }
                 {timestamp && <h5 className="client_list_time client_list_time-clicked">{ formatDatetimeFriendly(timestamp) }</h5>}
              </div>
              {chat && chat.length > 0 && (
                <div className="client_list_info">
                  <h5
                    className="client_list_msg">{truncateString(chat[chat.length - 1].content, 25)}</h5>
                 
                </div>
              )}
            </div>
          </li>
        :
          <li key={number} className="client_list_elem border-b border-b-gray-300 gap-3 flex flex-row px-2.5 py-3.5" onClick={handleClick} value={number}>
            <div className="profile-container">
              <div style={{background: nameClient ? useProfile.useNameToColor(nameClient) : useProfile.useNameToColor(number)}} className="profile_img">{nameClient ? useProfile.useInitials(nameClient) : useProfile.useInitials(number)}</div>
            </div>
            <div className="chat-container">
              <div className="name_notif">
                <h5 className="client_name_tag text-sm xl:text-[16px] font-medium">{nameClient ? nameClient : number}</h5>
                {timestamp && <h5 className={(newMsgNotif[number] && Number(newMsgNotif[number]) !== 0) ? "client_list_time client_list_time-active font-semibold text-lg ": "client_list_time font-semibold text-lg"}>{ formatDatetimeFriendly(timestamp) }</h5> }
              </div>

              {chat && chat.length > 0 && (
                <div className="client_list_info font-light text-sm xl:text-[16px]">
                  {chat[chat.length - 1].role === "user" && <>
                    <h5 className="client_list_msg_user">{truncateString(chat[chat.length - 1].content, 30)}</h5>
                  </>}
                  {chat[chat.length - 1].role === "assistant" && <>
                    <h5 className="client_list_msg_assistant">{truncateString(chat[chat.length - 1].content, 30)}</h5>
                  </>}
                  {chat[chat.length - 1].role === "manual" && <>
                    <h5 className="client_list_msg_manual">{truncateString(chat[chat.length - 1].content, 30)}</h5>
                  </>}
                  {chat[chat.length - 1].role === "media_assistant" && <>
                    <h5 className="client_list_msg_manual">{truncateString(chat[chat.length - 1].content, 25)}</h5>
                  </>}
                  <div className="flex flex-col">
                    
                    {(newMsgNotif[number] && Number(newMsgNotif[number]) !== 0) &&  <p className="newMessageNotif">{newMsgNotif[number]}</p>}
                  </div>
                </div>
              )}
            </div>
          </li>,
        )
      }
    </ol>
  );
}
