import React, {createContext, ReactNode, useContext, useState} from 'react';
import SessionManager from "../../context/session/SessionManager";

import { useNavigate } from "react-router-dom";
import useMobileDetect from "../../services/movil/useMobileDetect";
interface AuthContextType {
  session: any;
  login: (newSession: any) => void;
  logout: () => void;
}

const defaultAuthContextValue: AuthContextType = {
  session: null,
  login: () => {},
  logout: () => {}
};

const AuthContext = createContext<AuthContextType | null>(defaultAuthContextValue);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth debe usarse dentro de un AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { basePath } = useMobileDetect();
  let navigate = useNavigate();
  const [session, setSession] = useState(SessionManager.getSession());

  const login = (newSession: any) => {
    SessionManager.saveSession(newSession);
    setSession(newSession);
    navigate(`${basePath}/`);
  };

  const logout = () => {
    SessionManager.removeSession();
    setSession(null);
  };

  return (
    <AuthContext.Provider value={{ session, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
