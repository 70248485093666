import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [basePath, setBasePath] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (mobile) {
        setBasePath('/movil');
        navigate(`/movil${window.location.pathname.replace("movil/", "")}`);
      } else {
        setBasePath('');
        navigate(`${window.location.pathname.replace("/movil/", "")}`);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return  { isMobile, basePath };
};

export default useMobileDetect;
