import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const selectorSlice = createSlice({
    name : "selector",
    initialState : {
        value: ""
    },
    reducers:{
        select : (state, action: PayloadAction<string>) => {
              state.value = action.payload
        },
        reset : (state) =>{
            state.value = ""
        }
    }
})


export const {
    select,
    reset,
  } = selectorSlice.actions;

  export default selectorSlice.reducer;