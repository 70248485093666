import React, { useState } from "react";
import { Sparkles, File, Download } from "akar-icons";
import config from "../../../config/config";
import CopyButton from "../../shared/button/CopyButton";
import { parseTimestamp } from "../../../helper/helpers";

export default function MessageItem({ item, index, }: { item: any, index: any, }) {
  // const { onToggleExpand, isImageExpanded } = props;
  const [openClipboard, setOpenClipboard] = useState(false);
  const [copiedId, setCopiedId] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleCopy = (id: any) => {
    setOpenClipboard(true);
    setCopiedId(id);
    setTimeout(() => {
      setOpenClipboard(false);
      setCopiedId("");
    }, 1000);
  };

  const handleImageClick = () => {
    setIsExpanded(!isExpanded);
  };

  let timestampTransform = item.timestamp;
  let time = parseTimestamp(item.timestamp);
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  switch (item.role) {
    case "user":
      let messageContent2 = item.content;
      let formattedMessage3 = messageContent2.replace(/\*\s*([^*]+)\s*\*/g, ' <strong>$1</strong>');
      formattedMessage3 = formattedMessage3.replace(/(\d+\.\s*([^-\n]+)|-\s*([^-\n]+))\s*\n?/g, '<li>$1</li>');
      formattedMessage3 = formattedMessage3.replace(/\n/g, '<br/>');

      return (
        <div className="message_container mt-1">
          <div className="flex flex-col gap-1">
            <li className="message_user" key={index} dangerouslySetInnerHTML={{ __html: formattedMessage3 + `<h5 class="hidden">${timestampTransform}</h5>` }} /> <br />
            <h5 className="message_timestamp_user">{time["time"]}</h5>
          </div>
          {/*<div className="clipboard_button_message_container">
            <CopyButton textToCopy={item.content} id={index} onCopy={() => handleCopy(index)}/>
            {openClipboard && copiedId === index && <p className="clipboard_message">¡Copiado!</p>}
          </div>*/}
        </div>
      );
      case "notification_assistant":
        return (
          <div className="message_container mt-1 aling-end">
            <div className="flex flex-col gap-2 w-100">
              <li className="message_assistant_recordatory">
                <div className="flex flex-row w-35p"><Sparkles color="#f1c40f" strokeWidth={1.5} size={20} /> <Sparkles
                  color="#f1c40f" strokeWidth={1.5} size={20} /></div>
                <div className="body_msg_assistant"><h4  dangerouslySetInnerHTML={{ __html:  item.content}} /> <br />
                  <h5 className="hidden">{timestampTransform}</h5>
                </div>
              </li>
              <h5
                className="message_timestamp_manual_assist">{time["time"]}</h5>
            </div>
          </div>
        );
    case "assistant":
      let messageContent = item.content;
      let formattedMessage2 = messageContent.replace(/\*\s*([^*]+)\s*\*/g, ' <strong>$1</strong>');
      formattedMessage2 = formattedMessage2.replace(/(\d+\.\s*([^-\n]+)|-\s*([^-\n]+))\s*\n?/g, '<li>$1</li>');
      formattedMessage2 = formattedMessage2.replace(/\n/g, '<br/>');

      return (
        <div className="message_container mt-1 aling-end">
          {/*<div className="clipboard_button_message_container">
            <CopyButton textToCopy={item.content} id={index}/>
            {openClipboard && copiedId === index && <p className="clipboard_message">¡Copiado!</p>}
      </div>*/}
          <div className="flex flex-col gap-2 w-100">
            <li className="message_assistant">
              <div className="flex flex-row w-35p"><Sparkles color="#f1c40f" strokeWidth={1.5} size={20} /> <Sparkles
                color="#f1c40f" strokeWidth={1.5} size={20} /></div>
              <div className="body_msg_assistant"><h4  dangerouslySetInnerHTML={{ __html: formattedMessage2 }} /> <br />
                <h5 className="hidden">{timestampTransform}</h5>
              </div>

            </li>
            <h5
              className="message_timestamp_manual_assist">{time["time"]}</h5>
          </div>
        </div>
      );
    case "manual":
      let message = item.content;
      let formattedMessage = message.replace(/\*\s*([^*]+)\s*\*/g, ' <strong>$1</strong>');
      formattedMessage = formattedMessage.replace(/(\d+\.\s*([^-\n]+)|-\s*([^-\n]+))\s*\n?/g, '<li>$1</li>');
      formattedMessage = formattedMessage.replace(/\n/g, '<br/>');

      return (
        <div className="message_container mt-2 aling-end">
          {/*<div className="clipboard_button_message_container">
            <CopyButton textToCopy={item.content} id={index}/>
            {openClipboard && copiedId === index && <p className="clipboard_message">¡Copiado!</p>}
        </div>*/}
          <div className="flex flex-col gap-1">
            <li className="message_manual">
              <h4  dangerouslySetInnerHTML={{ __html: formattedMessage }} /> <br />
              <h5 className="hidden">{timestampTransform}</h5>

            </li>
            <h5 className="message_timestamp_manual_assist p-0">{time["time"]}</h5>
          </div>
        </div>
      );
    case "imagen":
      const pathImage = item.content
      return (
        <li className="message_user_media mt-2">
          <img
            src={pathImage}
            alt=""
            onClick={handleImageClick}
            className={isExpanded ? 'expandedImage' : ''}
          />
          <h5 className="hidden">{timestampTransform}</h5>
          <h5 className="message_timestamp_user">{time["time"]}</h5>
        </li>
      );
    case "media_assistant":
      let image = item.content
      if (!image.includes('https') || !image.includes('http')) {
        image = `${config.BACKEND_API_URL}${image}`;
      }
      let url = new URL(image);
      const pathSegments = url.pathname.split('.');
      const extension = pathSegments.pop();
      const pathSegments2 = url.pathname.split('/');
      const fileNameWithExtension = pathSegments2.pop();
      if(fileNameWithExtension === undefined) return null;
      const fileName = fileNameWithExtension.split('.pdf')[0];
      return (
        <>
          <li className="message_assistant_media_assistant mt-2 flex flex-col justify-end items-end">
            {(extension === 'pdf') ?
              <div className="message_assistant rigth-pdf">
                <div className="flex flex-row w-35p">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#f1c40f" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M5 2l.19.94a4 4 0 0 0 2.57 2.974L8 6l-.24.086A4 4 0 0 0 5.19 9.06L5 10l-.19-.94a4 4 0 0 0-2.57-2.974L2 6l.24-.086A4 4 0 0 0 4.81 2.94L5 2z"></path><path d="M8 16l.23 1.276a2 2 0 0 0 1.219 1.501L10 19l-.551.223a2 2 0 0 0-1.22 1.5L8 22l-.23-1.276a2 2 0 0 0-1.219-1.501L6 19l.551-.223a2 2 0 0 0 1.22-1.5L8 16z"></path><path d="M16 3l.556 2.654a8 8 0 0 0 5.213 5.92L23 12l-1.231.426a8 8 0 0 0-5.213 5.92L16 21l-.556-2.654a8 8 0 0 0-5.213-5.92L9 12l1.231-.426a8 8 0 0 0 5.213-5.92L16 3z"></path></svg> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#f1c40f" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M5 2l.19.94a4 4 0 0 0 2.57 2.974L8 6l-.24.086A4 4 0 0 0 5.19 9.06L5 10l-.19-.94a4 4 0 0 0-2.57-2.974L2 6l.24-.086A4 4 0 0 0 4.81 2.94L5 2z"></path><path d="M8 16l.23 1.276a2 2 0 0 0 1.219 1.501L10 19l-.551.223a2 2 0 0 0-1.22 1.5L8 22l-.23-1.276a2 2 0 0 0-1.219-1.501L6 19l.551-.223a2 2 0 0 0 1.22-1.5L8 16z"></path><path d="M16 3l.556 2.654a8 8 0 0 0 5.213 5.92L23 12l-1.231.426a8 8 0 0 0-5.213 5.92L16 21l-.556-2.654a8 8 0 0 0-5.213-5.92L9 12l1.231-.426a8 8 0 0 0 5.213-5.92L16 3z"></path>
                  </svg>
                </div>
                <div className="body_msg_assistant">
                  <h4 style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                    <div className="message_pdf">
                      <File className="center_pdf_content" strokeWidth={2} size={32} />
                      <div className="flex flex-col gap-0">
                        {fileName}
                        <span>PDF</span>
                      </div>
                      <div className="downloand_pdf">
                        <a className="margin-auto flex" href={`${url}`} target="_blank" rel="noopener noreferrer" download><Download className="m-auto" strokeWidth={2} size={25} /></a>
                      </div>
                    </div>
                  </h4>
                </div>
              </div>
              :
              <div>
                {isLoading && <div className="loading-media gap-2">
                  <svg width={"12px"} height={"12px"} className="animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z" />
                  </svg>Cargando...</div>}
                <img
                  src={image}
                  alt=""
                  onClick={handleImageClick}
                  onLoad={handleImageLoad}
                  className={isExpanded ? 'expandedImage' : ''}
                />
              </div>}
            <h5 className="hidden">{timestampTransform}</h5>
            <h5 style={{ textAlign: "end" }} className={(extension === 'pdf') ? "message_timestamp_user rigth-pdf" : "message_timestamp_user"}>{time["time"]}</h5>
          </li>

        </>
      );
    case "video":
      const pathMedia = item.content.replace('BASE_DATOS_CLIENTE', '')

      return (
        <li className="message_user_media mt-2">
          <video src={`${config.BACKEND_API_URL}/media/${pathMedia}`} controls />
          <h5 className="hidden">{timestampTransform}</h5>
          <h5 className="message_timestamp_user">{time["time"]}</h5>
        </li>
      );
    default:
      return null;
  }
};
