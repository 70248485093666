import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const currentPageSlice = createSlice({
  name : "currentPage",
  initialState : {
    value: 1
  },
  reducers:{
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.value = action.payload
    },
    resetCurrentPage: (state) =>{
      state.value = 1
    }
  }
})


export const {
  changeCurrentPage,
  resetCurrentPage,
} = currentPageSlice.actions;

export default currentPageSlice.reducer;
