import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const perPageSlice = createSlice({
  name : "perPage",
  initialState : {
    value: "50"
  },
  reducers:{
    changePerPage: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
    resetPerPage: (state) =>{
      state.value = "50"
    }
  }
})


export const {
  changePerPage,
  resetPerPage,
} = perPageSlice.actions;

export default perPageSlice.reducer;
