const config = {
  AUTH_SECRET: process.env.REACT_APP_AUTH_SECRET,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,

  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,

  BACKEND_API_URL: process.env.REACT_APP_BACKEND_API_URL,

  ACCESS_NAVBAR: process.env.REACT_APP_ACCESS_NAVBAR,
  DASHBOARD_FILTER: process.env.REACT_APP_DASHBOARD_FILTER,
}

export default config
