import * as React from "react";
import { Routes, Route, useLocation, useNavigate, Navigate as Redirect } from "react-router-dom";
import SignInPage from '../views/login';
import ForgetPassword from "../views/forget-password";
import SignUp from "../views/sign-up";
import Historial from "../views/historial";
import { useAuth } from "../context/auth/AuthContext";
import useMobileDetect from "../services/movil/useMobileDetect";

export default function Navigate() {
  let location = useLocation();
  let navigate = useNavigate();
  let state = location.state as { backgroundLocation?: Location };

  const { session } = useAuth();
  
  const { basePath } = useMobileDetect();

  React.useEffect(() => {
    if (!session && !['/login', '/forget-password', '/sign-up'].includes(location.pathname)) {
      navigate(`${basePath}/login`);
    }
  }, [session, navigate, location.pathname]);

  return (
    <Routes location={state?.backgroundLocation || location}>
      <Route path={`${basePath}/login`} element={<SignInPage />} />
      <Route path={`${basePath}/forget-password`} element={<ForgetPassword />} />
      <Route path={`${basePath}/sign-up`} element={<SignUp />} />
      <Route path={`${basePath}/`} element={session ? <Historial /> : <Redirect to={`${basePath}/login`} />} />
      <Route path={`${basePath}/historial`} element={session ? <Historial /> : <Redirect to={`${basePath}/login`} />} />
    </Routes>
  );
}
