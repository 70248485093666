import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const dateFilterBySlice = createSlice({
  name : "dateFilterBy",
  initialState : {
    value: "all",
    date: ""
  },
  reducers:{
    selectDateFilterBy: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
    selectCustomDateFilterBy: (state, action: PayloadAction<string>) => {
      state.date = action.payload
    },
    resetDateFilterBy : (state) =>{
      state.value = "all"
      state.date = ""
    }
  }
})


export const {
  selectDateFilterBy,
  selectCustomDateFilterBy,
  resetDateFilterBy,
} = dateFilterBySlice.actions;

export default dateFilterBySlice.reducer;
