import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const qSearchSlice = createSlice({
  name : "qSearch",
  initialState : {
    value: ""
  },
  reducers:{
    fillQSearch: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
    resetQSearch : (state) =>{
      state.value = ""
    }
  }
})


export const {
  fillQSearch,
  resetQSearch,
} = qSearchSlice.actions;

export default qSearchSlice.reducer;
