import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const menuChange = createSlice({
    name : "manu",
    initialState : {
        value: true
    },
    reducers:{
        state : (state, action: PayloadAction<boolean>) => {
              state.value = action.payload
        }
    }
})


export const {
    state,
  } = menuChange.actions;

  export default menuChange.reducer;