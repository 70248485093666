import { configureStore } from "@reduxjs/toolkit";
import selectorReducer from "./features/selectorSlice"
import selectorModalReducer from "./features/selectorModalSlice"
import newMessageReducer from "./features/newMessageSlice"
import unreadMessageReducer from "./features/unreadMessageSlice"
import modalReducer from "./features/modalSlice"
import Menu from "./reducers/changeMenu"
import loadingChatListReducer from "./features/loadingChatList";
import currentPageReducer from "./features/currentPageSlice";
import sortByReducer from "./features/sortBySlice";
import filterByReducer from "./features/filterBySlice";
import qSearchReducer from "./features/qSearchSlice";
import infoPaginationReducer from "./features/infoPaginationSlice";
import phoneContactReducer from "./features/phoneContactSlice";
import perPageReducer from "./features/perPageSlice";
import hotnessByReducer from "./features/hotnessBySlice";
import dateFilterByReducer from "./features/dateFilterBySlice";

export const store = configureStore({
    reducer: {
        selectorReducer,
        selectorModalReducer,
        Menu,
        newMessageReducer,
        unreadMessageReducer,
        modalReducer,
        loadingChatListReducer,
        currentPageReducer,
        sortByReducer,
        filterByReducer,
        qSearchReducer,
        infoPaginationReducer,
        phoneContactReducer,
        perPageReducer,
        hotnessByReducer,
        dateFilterByReducer,
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;