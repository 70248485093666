import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const loadingChatListSlice = createSlice({
  name : "loadingChatList",
  initialState : {
    value: false
  },
  reducers:{
    toggleLoadingChatList: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload
    },
    resetLoading: (state) =>{
      state.value = false
    }
  }
})


export const {
  toggleLoadingChatList,
  resetLoading,
} = loadingChatListSlice.actions;

export default loadingChatListSlice.reducer;
