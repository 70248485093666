import ChatList from './ChatList';
import ChatBoard from './ChatBoard';
import { useAppSelector } from '../../redux/hooks';


function ChatContent({chatData, pausedNumber}:any) {
  const selection = useAppSelector(state => state.selectorReducer.value)
  const pausedOrNot = pausedNumber.some((number:any) => number === selection)

  return (
    <div className='w-full'>
      <div className='phone-conversation grid grid-cols-[30%_70%] gap-1 m-4'>
        <ChatList chatData={chatData} className="col-span-1" />
        <ChatBoard chatData={chatData} pausedOrNot={pausedOrNot} className="col-span-4" />
      </div>
    </div>
  );

}

export default ChatContent;
