import React, { useState, useEffect } from "react";
import ChatContent from "../components/chat/ChatContent";

import { useAppDispatch, useAppSelector } from "../redux/hooks"

import { resetLoading, toggleLoadingChatList } from "../redux/features/loadingChatList";
import { fillInfoPagination } from "../redux/features/infoPaginationSlice";

import { set } from "../redux/features/newMessageSlice"
import Loading from "../components/shared/Loading";

import historicalService from "../services/historical/historicalService";
import { sha256 } from 'js-sha256';
import DashboardYa from "../template/DashboardYa";
import { resetCurrentPage } from "../redux/features/currentPageSlice";
import { fillPhoneContact } from "../redux/features/phoneContactSlice";


export default function HistoricalPage() {
  const [chatData, setChatData] = useState(""); // Adjust the initial state as needed
  const [pausedNumber, setPausedNumber] = useState([]);
  const [newMessages, setNewMessages] = useState("");
  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();

  const [dataHash, setDataHash] = useState('');
  const [stoppedMessageHash, setStoppedMessages] = useState('');
  const [notReadMessageHash, setNotReadMessageHash] = useState('');
  const [debounceTimer, setDebounceTimer] = useState<any>(null);

  const perPage = useAppSelector(state => state.perPageReducer.value);
  const currentPage = useAppSelector(state => state.currentPageReducer.value);
  const hotnessBy = useAppSelector(state => state.hotnessByReducer.value);
  const sortBy = useAppSelector(state => state.sortByReducer.value);
  const filterBy = useAppSelector(state => state.filterByReducer.value);
  const dateFilterBy = useAppSelector(state => state.dateFilterByReducer.value);
  const qSearch = useAppSelector(state => state.qSearchReducer.value);

  const updateChatData = async () => {
    const res = await historicalService.getConversations(currentPage, Number.parseInt(perPage), hotnessBy, sortBy, filterBy, dateFilterBy, qSearch);

    if (res.status === 200) {
      const newData = res.data.conversations;
      const newHash = sha256(JSON.stringify(newData));

      const newStoppedMessageHash = sha256(JSON.stringify(res.data.messages_stopped))
      const newNotReadMessageHash = sha256(JSON.stringify(res.data.num_conv))


      if (newHash !== dataHash) {
        setChatData(res.data.conversations);
        setLoading(false);

        dispatch(
          fillInfoPagination({
            currentPage: res.data.current_page,
            perPage: res.data.per_page,
            isLastPage: res.data.is_last_page,
            nextPage: res.data.next_page,
            totalConversations: res.data.total_conversations,
            totalPages: res.data.total_pages,
          })
        );
        dispatch(
          fillPhoneContact(res.data.dict_phones)
        );

        setDataHash(newHash);
      }

      if (newStoppedMessageHash !== stoppedMessageHash) {
        setPausedNumber(res.data.messages_stopped);

        setStoppedMessages(newStoppedMessageHash);
      }

      if (newNotReadMessageHash !== notReadMessageHash) {
        dispatch(set(res.data.num_conv as any));
        setNewMessages(res.data.num_conv);

        setNotReadMessageHash(newNotReadMessageHash);
      }else{
        dispatch(set(res.data.num_conv as any));
        setNewMessages(res.data.num_conv);
      }

    }
  };

  const useChatData = () => {
    useEffect(() => {
      const intervalId = setInterval(updateChatData, 10000);
      return () => clearInterval(intervalId);
    }, [perPage, currentPage, hotnessBy, sortBy, filterBy, dateFilterBy, qSearch, dataHash, stoppedMessageHash, notReadMessageHash]);

    return chatData;
  };

  useChatData();

  useEffect(() => {
    if (currentPage !== 1) {
      dispatch(resetCurrentPage());
    }
  }, [filterBy]);

  useEffect(() => {
    dispatch(toggleLoadingChatList(true));
    updateChatData().then(r => dispatch(resetLoading()));
  }, [perPage, currentPage, hotnessBy, sortBy, filterBy, dateFilterBy,]);

  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      dispatch(toggleLoadingChatList(true));

      updateChatData().then(r => {
        dispatch(resetLoading());
        if (currentPage !== 1) {
          dispatch(resetCurrentPage());
        }
      });
    }, 1500);

    setDebounceTimer(newTimer);

    return () => clearTimeout(newTimer);
  }, [qSearch]);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return (
    <DashboardYa>
      {chatData && pausedNumber && <ChatContent chatData={chatData} loading={loading} pausedNumber={pausedNumber} />}
    </DashboardYa>
  );
}
