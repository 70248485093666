import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const filterBySlice = createSlice({
  name : "filterBy",
  initialState : {
    value: "all"
  },
  reducers:{
    selectFilterBy: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
    resetFilterBy : (state) =>{
      state.value = "all"
    }
  }
})


export const {
  selectFilterBy,
  resetFilterBy,
} = filterBySlice.actions;

export default filterBySlice.reducer;
