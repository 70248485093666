import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";

import { changeCurrentPage } from "../../../redux/features/currentPageSlice";

interface InfoPagination {
  currentPage: number;
  perPage: number;
  isLastPage: boolean;
  nextPage?: number;
  totalConversations: number;
  totalPages: number;
}

export default function PaginationChatList () {
  const currentPage = useAppSelector(state => state.currentPageReducer.value);
  const infoPagination = (useAppSelector(state => state.infoPaginationReducer.value)) as InfoPagination;

  const dispatch = useAppDispatch()

  useEffect(() => {
  }, [currentPage, infoPagination]);

  const clickPrevPage = () => {
    dispatch(changeCurrentPage(currentPage - 1));
  }

  const clickNextPage = () => {
    dispatch(changeCurrentPage(currentPage + 1));
  }

  const calculatePreCountConversation = () => {
    if (infoPagination.isLastPage) {
      return infoPagination.totalConversations;
    }

    let totalConversations = 0;

    for (let i = 1; i <= infoPagination.currentPage; i++) {
      totalConversations += infoPagination.perPage;
    }

    return totalConversations;
  }

  return (
    <div className="w-full flex items-center text-sm font-extralight">
      <div className="w-1/2 flex items-center">
        {
          currentPage > 1 &&
          <p className="cursor-pointer hover:bg-gray-100 rounded-md p-1 mr-0.5" onClick={clickPrevPage}>
            {"<"}
          </p>
        }
        <div className="cursor-default text-xs xl:text-sm px-2 py-0.5 bg-gray-200 rounded-md mr-0.5">
          {currentPage}
        </div>
        {
          (infoPagination.nextPage && infoPagination.nextPage > 1) &&
          <p className="cursor-pointer hover:bg-gray-100 rounded-md p-1" onClick={clickNextPage}>
            {">"}
          </p>
        }
      </div>
      <div className="w-1/2 flex justify-end">
        <p className="text-xs xl:text-sm">
          Mostrando <span>{ calculatePreCountConversation() }</span> de <span>{infoPagination.totalConversations}</span>
        </p>
      </div>
    </div>
  );
}
