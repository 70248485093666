import React from 'react';

import Navigate from './navigate/navigate.tsx';
import { AuthProvider } from "./context/auth/AuthContext";
import "./assets/sass/init.scss";
function App() {
  return (
    <div className="body">
      <AuthProvider>
        <Navigate></Navigate>
      </AuthProvider>
    </div>
  );
}

export default App;

