import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const sortBySlice = createSlice({
  name : "sortBy",
  initialState : {
    value: "default"
  },
  reducers:{
    selectSortBy: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
    resetSortBy : (state) =>{
      state.value = ""
    }
  }
})


export const {
  selectSortBy,
  resetSortBy,
} = sortBySlice.actions;

export default sortBySlice.reducer;
