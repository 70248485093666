import React, { ReactNode } from 'react';

import LeftNavbar from "../components/shared/layout/LeftNavbar";
import NavbarUser from "../components/shared/layout/NavbarUser";

export default function DashboardYa({ children }: { children: ReactNode }) {
  return (
    <main className='flex-main' style={{backgroundColor: "#ECECEC"}}>
      <LeftNavbar/>
      <div className='body-flex'>
        <NavbarUser/>
        {children}
      </div>
    </main>
  );
}
