import React from 'react';
import Logo from "../../assets/img/yavendio.png";

export default function Loading (){
  return(
    <>
      <div className="loading_container">
        <img className="loading_image" src={Logo} alt="Logo" />
        <p className="loading_message" > Cargando sus conversaciones... </p>
      </div>
    </>
  )

}
