import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const infoPaginationSlice = createSlice({
  name : "infoPagination",
  initialState : {
    value: {}
  },
  reducers:{
    fillInfoPagination: (state, action: PayloadAction<object>) => {
      state.value = action.payload
    },
    resetInfoPagination : (state) =>{
      state.value = {}
    }
  }
})


export const {
  fillInfoPagination,
  resetInfoPagination,
} = infoPaginationSlice.actions;

export default infoPaginationSlice.reducer;
