import React, { useState, ReactNode, useEffect, useRef } from "react";
import { Cross } from "akar-icons";

interface CustomCheckboxDropdownProps {
  label: string;
  isSelectAll: boolean;
  options: Array<{ label: string; value: string }>;
  onChange: (selectedValues: string) => void;
  icon?: ReactNode;
  disabled?: boolean;
}

const CustomCheckboxDropdown = ({
  label,
  isSelectAll = false,
  options,
  onChange,
  icon,
  disabled,
}: CustomCheckboxDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(isSelectAll);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (selectAll) {
      setSelectedValues(options.map((option) => option.value));
      onChange(options.map((option) => option.value).join(","));
    }
  }, []);

  const handleSelectAllChange = () => {
    if (!selectAll) {
      setSelectedValues(options.map((option) => option.value));
      onChange(options.map((option) => option.value).join(","));
    } else {
      setSelectedValues([]);
      onChange("");
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (value: string) => {
    const currentIndex = selectedValues.indexOf(value);
    const newSelectedValues = [...selectedValues];

    if (currentIndex === -1) {
      newSelectedValues.push(value);
    } else {
      newSelectedValues.splice(currentIndex, 1);
    }

    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues.join(","));

    setSelectAll(newSelectedValues.length === options.length);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && (dropdownRef.current as HTMLElement).contains(event.target)) {
        return;
      }else{
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative cursor-pointer ml-2">
      <div
        onClick={() => { (!disabled) ? toggleDropdown() : console.log("disabled") }}
        className="flex items-center text-sm transparent text-gray-700 font-medium p-2 rounded-md"
      >
        {icon && <span className="h-4 transparent">{icon}</span>}
        {label && <span className="mr-1">{label}</span>}
      </div>
      {isOpen && (
        <div className="phone-desplegable origin-top-right absolute z-50 right-0 mt-0 text-sm w-[160px] rounded-md bg-white shadow-lg">
          <label className="flex items-center space-x-2 ml-auto">
            <div
              onClick={toggleDropdown}
              className="btn-close flex items-center text-sm transparent text-gray-700 font-medium p-1 rounded-md"
            >
              <Cross size={16} />
            </div>
          </label>
          <ul className="py-2 text-gray-700">
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-b-gray-300">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  onChange={handleSelectAllChange}
                  checked={selectAll}
                />
                <span>All</span>
              </label>
            </li>
            {options.map((option) => (
              <li
                key={option.value}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value={option.value}
                    checked={selectedValues.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value)}
                  />
                  <span>{option.label}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomCheckboxDropdown;
