import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const modalSlice = createSlice({
    name : "modal",
    initialState : {
        value: false,
        value2: false,
        value3: false,
        value4: false
    },
    reducers:{
        open : (state, action: PayloadAction<boolean>) => {
              state.value = true
        },
        close : (state, action: PayloadAction<boolean>) => {
              state.value = false
        },
        open2 : (state2, action: PayloadAction<boolean>) => {
              state2.value2 = true
        },
        close2 : (state2, action: PayloadAction<boolean>) => {
              state2.value2 = false
        },
        open3 : (state3, action: PayloadAction<boolean>) => {
              state3.value3 = true
        },
        close3 : (state3, action: PayloadAction<boolean>) => {
              state3.value3 = false
        },
        open4 : (state4, action: PayloadAction<boolean>) => {
              state4.value4 = true
        },
        close4 : (state4, action: PayloadAction<boolean>) => {
              state4.value4 = false
        },
       
    }
})


export const {
    open,
    close,
    open2,
    close2,
    open3,
    close3,
    open4,
    close4
  } = modalSlice.actions;

  export default modalSlice.reducer;