import { useAuth } from "../../../context/auth/AuthContext";
import useMobileDetect from "../../../services/movil/useMobileDetect";
import { useLocation } from "react-router-dom";
import { state } from '../../../redux/reducers/changeMenu';
import { useSelector, useDispatch } from 'react-redux';

export default function NavbarUser() {
  const dispatch = useDispatch();
  const { session } = useAuth();
  const { isMobile } = useMobileDetect();
  const location = useLocation();
  const menu = useSelector((state:any) => state.Menu.value);
  const changeMenu = () => {
    dispatch(state(false));
  };

  return session ? (
    <div className="w-full margen-Nabvar py-6 pb-8">
      {isMobile && (
        <div className="flex justify-between items-center w-100">
          <div onClick={() => changeMenu()} className="flex menu-phone">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <g clip-path="url(#clip0_1515_3397)">
                <path
                  d="M20 18.5C20.2549 18.5003 20.5 18.5979 20.6854 18.7728C20.8707 18.9478 20.9822 19.187 20.9972 19.4414C21.0121 19.6958 20.9293 19.9464 20.7657 20.1418C20.6021 20.3373 20.3701 20.4629 20.117 20.493L20 20.5H4C3.74512 20.4997 3.49997 20.4021 3.31463 20.2272C3.1293 20.0522 3.01777 19.813 3.00283 19.5586C2.98789 19.3042 3.07067 19.0536 3.23426 18.8582C3.39786 18.6627 3.6299 18.5371 3.883 18.507L4 18.5H20ZM20 11.5C20.2652 11.5 20.5196 11.6054 20.7071 11.7929C20.8946 11.9804 21 12.2348 21 12.5C21 12.7652 20.8946 13.0196 20.7071 13.2071C20.5196 13.3946 20.2652 13.5 20 13.5H4C3.73478 13.5 3.48043 13.3946 3.29289 13.2071C3.10536 13.0196 3 12.7652 3 12.5C3 12.2348 3.10536 11.9804 3.29289 11.7929C3.48043 11.6054 3.73478 11.5 4 11.5H20ZM20 4.5C20.2652 4.5 20.5196 4.60536 20.7071 4.79289C20.8946 4.98043 21 5.23478 21 5.5C21 5.76522 20.8946 6.01957 20.7071 6.20711C20.5196 6.39464 20.2652 6.5 20 6.5H4C3.73478 6.5 3.48043 6.39464 3.29289 6.20711C3.10536 6.01957 3 5.76522 3 5.5C3 5.23478 3.10536 4.98043 3.29289 4.79289C3.48043 4.60536 3.73478 4.5 4 4.5H20Z"
                  fill="#009DD9"
                />
              </g>
              <defs>
                <clipPath id="clip0_1515_3397">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="title-phone flex m-auto">
              {(location.pathname === "/" || location.pathname === "/movil/" || location.pathname === "historial" || location.pathname === "/movil/historial")? "Conversaciones" : location.pathname.replace('/movil/','').toUpperCase()}
          </div>
        </div>
      )}
      <div className="relative inline-block">
        {/*<img className="logo-navbar" src={require("../../../assets/img/logo-sentir.png")} alt="logo" />
          <button
            type="button"
            className="px-4 py-2 text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center"
            onClick={toggleDropdown2}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_523_2438)">
                <path d="M11.83 11.64C13.774 11.64 15.35 10.064 15.35 8.11998C15.35 6.17593 13.774 4.59998 11.83 4.59998C9.88596 4.59998 8.31 6.17593 8.31 8.11998C8.31 10.064 9.88596 11.64 11.83 11.64Z" stroke="#121212" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.07 18.56C17.92 15.69 15.12 13.66 11.84 13.66C8.55999 13.66 5.74999 15.69 4.60999 18.56" stroke="#121212" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_523_2438">
                  <rect width="15.67" height="15.16" fill="white" transform="translate(4 4)"/>
                </clipPath>
              </defs>
            </svg>

          </button>

          {isOpen2 && !isOpen && (
            <div className=" origin-top-right absolute z-30 right-0 mt-2 w-44 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <ul role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {/* <li>
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={closeDropdown2}
                            >
                                Perfil
                            </a>
                        </li> }
                <hr></hr>
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 z-30 hover:bg-gray-100"
                    onClick={() => {logout();closeDropdown2();}}
                  >
                    Cerrar Sesión
                  </a>
                </li>
              </ul>
            </div>
          )}*/}
      </div>
    </div>
  ) : (
    <></>
  );
}
