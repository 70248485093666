import React, { useState, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectSortBy } from "../../redux/features/sortBySlice";

import { Filter, Fire, XSmall } from "akar-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CustomDropdown from "../shared/dropdown/CustomDropdown";
import CustomCheckboxDropdown from "../shared/dropdown/CustomCheckboxDropdown";

import config from '../../config/config';
import historicalService from "../../services/historical/historicalService";
import { selectFilterBy } from "../../redux/features/filterBySlice";
import { fillQSearch } from "../../redux/features/qSearchSlice";
import ChatListItem from "./widget/ChatListItem";
import PaginationChatList from "./widget/PaginationChatList";
import { changePerPage } from "../../redux/features/perPageSlice";
import { selectHotnessBy } from "../../redux/features/hotnessBySlice";
import {selectDateFilterBy} from "../../redux/features/dateFilterBySlice";

export default function ChatList({ chatData }: any) {
  const [sortedData, setSortedData] = useState<string[]>([]);

  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const dispatch = useAppDispatch();

  // State Global
  const phoneContact = useAppSelector(state => state.phoneContactReducer.value) as any;
  const selection = useAppSelector(state => state.selectorReducer.value);
  const sortBy = useAppSelector(state => state.sortByReducer.value);
  const hotnessBy = useAppSelector(state => state.hotnessByReducer.value);
  const qSearch = useAppSelector(state => state.qSearchReducer.value);
  const perPage = useAppSelector(state => state.perPageReducer.value);
  const isLoadingChatList = useAppSelector(state => state.loadingChatListReducer.value);
  const dateFilterBy = useAppSelector(state => state.dateFilterByReducer.value);

  const tempChats = {
    coldOnes: ["51977152509"],
    warmOnes: ["51930140397"],
    hotOnes: ["51970244026", "5493549461380"]
  };

  const handleFilteringChange = async (value: string) => {
    dispatch(selectFilterBy(value));
  };

  const handleSortingChange = async (value: string) => {
    dispatch(selectSortBy(value));
  };

  const handlePerPageChange = async (value: string) => {
    dispatch(changePerPage(value));
  }

  const handleHotnessByChange = async (value: string) => {
    dispatch(selectHotnessBy(value));
  }

  const handleDateFilterChange = async (value: string) => {
    dispatch(selectDateFilterBy(value));
  }

  async function restablecerNotificacion() {
    const messageAdmin = {
      "numero_telefono": selection,
    }

    return await historicalService.postUpdateQuantity(messageAdmin);
  }

  interface conversationData {
    number: number,
    chat: any[],
  }

  useEffect(() => {
    const timestamps = chatData.map((raw: conversationData) => {
      const number = raw.number;
      const data = raw.chat;

      return ({
        number,
        timestamp: data?.length ? data[data.length - 1].timestamp : undefined,
        nameClient: phoneContact[number] ?? '',
        chat: data
      });
    })

    setSortedData(timestamps)
  }, [chatData, phoneContact]);

  useEffect(() => {
    const fetchData = async () => {
      await restablecerNotificacion();
    };

    if (selection) {
      fetchData();
    }
  }, [selection]);

  function handleOnChange(e: any) {
    dispatch(fillQSearch(e.target.value))
  }

  // let hasFilterHotAndCold = (config.DASHBOARD_FILTER ?? '').split(',').filter((item) => item === 'frios').length > 0;
  // let hasFilter = (config.DASHBOARD_FILTER ?? '').split(',').filter((item) => item === 'filtros').length > 0;

  return (
    <div className="client_list_container p-1 lg:p-3 xl:p-5 relative rounded-lg">
      {isLoadingChatList && (
        <div className="loading-overlay">
          <div className="loading-content flex items-center justify-center">
            <div className="w-4 h-4 mr-2">
              <svg className="animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z" />
              </svg>
            </div>
            <p className="italic text-sm font-normal">Actualizando conversaciones ...</p>
          </div>
        </div>
      )}
      <div className="w-full">
        <div className="w-full flex items-center justify-center">
          <div className="w-1/2 flex flex-row items-center">
            <div className="w-4 h-4 mx-2">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.0604 11.9141C9.74105 11.9141 11.9141 9.74105 11.9141 7.0604C11.9141 4.37976 9.74105 2.20667 7.0604 2.20667C4.37976 2.20667 2.20667 4.37976 2.20667 7.0604C2.20667 9.74105 4.37976 11.9141 7.0604 11.9141Z"
                  stroke="#121212" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M10.4951 10.4949L13.3336 13.3334" stroke="#121212" stroke-width="1.3" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <input className="inputSearch text-sm" type="text" placeholder="Buscar" onChange={handleOnChange}></input>
          </div>
          <div className="w-1/2 flex justify-end">
            <CustomCheckboxDropdown
              key="custom-checkbox-chatlist"
              label=""
              isSelectAll={true}
              options={[
                { label: 'No leidos', value: 'unread' },
                { label: 'Leidos', value: 'read' },
                { label: 'Pausados', value: 'stopped' },
                { label: 'No pausados', value: 'ready' },
              ]}
              onChange={handleFilteringChange}
              icon={
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.66675 5.66659H18.0001V8.33325H4.66675V5.66659ZM0.666748 0.333252H22.0001V2.99992H0.666748V0.333252ZM8.66675 10.9999H14.0001V13.6666H8.66675V10.9999Z" fill="#5C5C5C" />
                </svg>
              }
            />
          </div>
        </div>
        <hr className="w-full border-gray-400 my-1" />
        <div className="w-full flex justify-end">
          <CustomDropdown
            key="custom-dropdown-chatlist"
            label="Mostrar"
            isSmall={true}
            model={perPage}
            options={[
              { label: '50', value: '50' },
              { label: '100', value: '100' },
              { label: '200', value: '200' },
            ]}
            onChange={handlePerPageChange}
            icon={
              <svg width="14px" height="14px" fill="#5C5C5C" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM224 64H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
              </svg>
            }
          />
          <div className="mx-1.5"></div>
          <CustomDropdown
            key="custom-dropdown-chatlist"
            label="Fechas"
            isSmall={true}
            model={dateFilterBy}
            options={[
              { label: 'All', value: 'all' },
              { label: 'Hoy', value: 'today' },
              { label: 'Ayer', value: 'yesterday' },
              { label: 'Esta semana', value: 'week' },
              { label: 'Ultimos 24 horas', value: 'last-24h' },
              { label: 'Ultimos 7 dias', value: 'last-7d' },
            ]}
            onChange={handleDateFilterChange}
            icon={
              <svg height="14px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/>
              </svg>
            }
          />
          <div className="mx-1.5"></div>
          <CustomDropdown
            key="custom-checkbox-chatlist"
            label="Hotness"
            isSmall={true}
            model={hotnessBy}
            options={[
              {label: 'All', value: 'all'},
              {label: 'Frios', value: 'cold'},
              {label: 'Tibios', value: 'warm'},
              {label: 'Calientes', value: 'hot'},
            ]}
            onChange={handleHotnessByChange}
            icon={
              <svg height="14px" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 320 512">
                <path
                  d="M160 64c-26.5 0-48 21.5-48 48V276.5c0 17.3-7.1 31.9-15.3 42.5C86.2 332.6 80 349.5 80 368c0 44.2 35.8 80 80 80s80-35.8 80-80c0-18.5-6.2-35.4-16.7-48.9c-8.2-10.6-15.3-25.2-15.3-42.5V112c0-26.5-21.5-48-48-48zM48 112C48 50.2 98.1 0 160 0s112 50.1 112 112V276.5c0 .1 .1 .3 .2 .6c.2 .6 .8 1.6 1.7 2.8c18.9 24.4 30.1 55 30.1 88.1c0 79.5-64.5 144-144 144S16 447.5 16 368c0-33.2 11.2-63.8 30.1-88.1c.9-1.2 1.5-2.2 1.7-2.8c.1-.3 .2-.5 .2-.6V112zM208 368c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-20.9 13.4-38.7 32-45.3V272c0-8.8 7.2-16 16-16s16 7.2 16 16v50.7c18.6 6.6 32 24.4 32 45.3z"/>
              </svg>
            }
          />
        </div>
      </div>
      <div className={`phone-content h-[70%] lg:[75%] xl:h-[77%] mb-0 lg:mb-3 ${isLoadingChatList ? "blur-effect" : ""}`}>
        <ChatListItem
          items={sortedData}
        />
      </div>
      <PaginationChatList />
    </div>
  );

}
