import React from "react";
import CredentialsForm from "../components/CredentialsForm";
import useMobileDetect from "../services/movil/useMobileDetect";
export default function SignInPage() {
  const { isMobile } = useMobileDetect();
  return (
    <div className="card-degrade w-100 h-100">
      <div className="card-login h-auto">
        <div className="sign_in_left_side">
          <div className="sign_in_formulario">
            <div className="center-items">
              {(isMobile)? <img className="logoForm" src={require("../assets/img/Logo.png")} alt="" /> :<img className="logoForm" src={require("../assets/img/Logoblanco.png")} alt="" />}
            </div>
            <CredentialsForm />
            <div className="card-conocenos">
              <span>¿Nuevo aquí?</span>
              <button className="btn btn-secondary" onClick={() => window.open('https://www.yavendio.com', '_blank')}>Conócenos</button>
            </div>
          </div>
        </div>
        <div className="divisorLogin"></div>
        <div className="sign_in_right_side">
          <div className="center-items">
            <img className="logoLoginForm" src={require("../assets/img/loginImage.png")} alt="" />
          </div>
        </div>
      </div>
    </div>
  );

}
